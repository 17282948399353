import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import Footer from '../components/Footer';
import LineFull from '../images/linefull.jpg';
import SEO from '../components/seo';
import SidebarCategories from '../components/sidebar/Categories';
import Search from '../components/sidebar/Search';
import Archives from '../components/sidebar/Archives';
import Schedule from '../components/sidebar/Schedule';
import Shop from '../components/sidebar/Shop';
import '../styles/app.scss';

const filterObject = (obj, filter, filterValue) =>
    Object.keys(obj).reduce(
        (acc, val) =>
            obj[val][filter] === filterValue
                ? acc
                : {
                      ...acc,
                      [val]: obj[val],
                  },
        {}
    );

const archive = ({ data, pageContext }) => {
    const { postId } = pageContext;
    // post data
    const { allWordpressPost: articles } = data;
    // name months
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    let filteredPosts = [];

    Object.values(postId).forEach(postID => {
        Object.values(articles.nodes).forEach(article => {
            if (article.wordpress_id === postID) {
                filteredPosts = filteredPosts.concat(article);
            }
        });
    });

    return (
        <>
            <SEO title={pageContext.title} />
            <HeaderMain />
            <HeroSingle pageTitle={pageContext.title} />
            <section className="page-section">
                <div className="container blogwrapper">
                    <div className="bloglftwrap">
                        {filteredPosts.map(article => (
                            <div className="bloglft" key={article.id}>
                                <div className="blogimg">
                                    <Img
                                        fluid={
                                            article.featured_media.localFile
                                                .childImageSharp.fluid
                                        }
                                    />
                                </div>
                                <div className="bloxexc">
                                    <Link
                                        to={`/${article.slug}`}
                                        className="postName"
                                    >
                                        <h2
                                            dangerouslySetInnerHTML={{
                                                __html: article.title,
                                            }}
                                        />
                                    </Link>
                                    <h5>
                                        {
                                            months[
                                                new Date(
                                                    article.date
                                                ).getMonth()
                                            ]
                                        }{' '}
                                        {new Date(article.date).getDate()},
                                        {new Date(article.date).getFullYear()}
                                    </h5>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: article.excerpt,
                                        }}
                                    />

                                    <Link to={`/${article.slug}`}>
                                        Read More
                                    </Link>
                                </div>
                                <img
                                    className="blogline"
                                    src={LineFull}
                                    alt="linefull"
                                />
                            </div>
                        ))}
                    </div>

                    <div className="blogsidebar">
                        <SidebarCategories />
                        <Search />
                        <Archives />
                        <Schedule />
                        <Shop />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default archive;

export const pageQuery = graphql`
    query {
        allWordpressPost (sort: { fields: [date], order: DESC }){
            nodes {
                id
                slug
                categories {
                    name
                    slug
                }
                excerpt
                title
                date
                wordpress_id
                featured_media {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
        }
    }
`;
